import { spinnerDefaultOptions } from "./main.inlined.js" ;
import Spinner from "spin" ;
import { setDataLayerPushForNewsletter } from "./main.js";
import { setDataLayerIdentication } from "./main.js";
import { setDataLayerCreateCompte } from "./main.js";

$(document).ajaxSuccess(function (response) {
    if (typeof response !== 'undefined' && typeof response.code !== 'undefined' && response.code === 401) {
        $('.overlay-connection-registration').addClass('active');
    }
});
$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('#msgDate').hide();
    $('#comments_dateConsomationVin').on('blur',function () {
        var testDate = isValidDate($('#comments_dateConsomationVin').val());

        if (testDate === false) {
            $('#msgDate').show();
            $('#comments_dateConsomationVin').css({'box-shadow':'0px 0px 5px red inset','box-shadow':'0px 0px 5px red'});
            // $('#comments_dateConsomationVin').val('');
        }else {
            $('#msgDate').hide();
            $('#comments_dateConsomationVin').css({'box-shadow':'0px 0px 5px white inset'});
        }
    });
});

function isValidDate(date) {
    var temp = date.split('/');
    var d = new Date(temp[2], (Number(temp[1]) - 1), temp[0]);
    return (d && (d.getMonth() + 1) === Number(temp[1]) && d.getDate() === Number(temp[0]) && d.getFullYear() === Number(temp[2]));
}

//write cookie
function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}
//read cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
//erase cookie
function eraseCookie(name) {
    createCookie(name, "", -1);
}

function openNewsletter() {
    setTimeout(function () {
        $('.overlay-newsletter').addClass('active');
    }, 1000);
}

function hideHint() {
    if ($('#registration-form .form-row.required .hint').length > 0) {
        setTimeout(function () {
            $('.form-row .hint').remove();
        }, 2000);
        return false;
    }
}

// Scroll Up
(function ($, window, document) {
    'use strict';
    var setConnectionRegistrationLoading = function () {
        var spinnerOptions = spinnerDefaultOptions;
        $.extend(spinnerOptions, {
            lines: 13,
            length: 40,
            width: 21,
            radius: 48
        });
        return new Spinner(spinnerOptions);
    };

    var setCommentLoading = function () {
        var spinnerOptions = spinnerDefaultOptions;
        $.extend(spinnerOptions, {
            lines: 10,
            length: 4,
            width: 4,
            radius: 8,
            top: 10
        });
        return new Spinner(spinnerOptions);
    };

    /* Validation :  popup identification */
    $(document).on('submit', '#login-form', function () {
        var isValid = 0;

        $('#login-form .form-row.required').each(function () {
            var $this = $(this);
            var error = $this.find('.messageError').val();
            var input = $this.find('input');
            var email = $('#username').val();
            var pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
            var errorEmail = $('.invalidError').val();

            if (input.val() === '') {
                isValid = 0;
                $this.removeClass('error').addClass('error');
                $this.append('<div class="hint"><i class="icon icon-error-red"></i>' + error + '</div>');
                return false;
            } else if(input.attr('id') === 'username') {
                if (pattern.test(email)) {
                    isValid = 1;
                } else {
                    $this.removeClass('error').addClass('error');
                    $('.invalidEmailMessage').append('<div class="hint"><i class="icon icon-error-red"></i> ' + errorEmail + '</div>');
                }
            }
        });

        if ($('#login-form .form-row.required .hint').length > 0) {
            setTimeout(function () {
                $('.form-row .hint').remove();
            }, 2000);
            return false;
        }

        if (isValid === 1) {
            $('#login-form-alert').hide();
            var spinner = setConnectionRegistrationLoading();
            spinner.spin($('.overlay-connection-registration')[0]);
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function (response) {
                    if (response.code === 200) {
                        setDataLayerIdentication();
                        $('.overlay-connection-registration').removeClass('active');
                        var urlEncours = $(location).attr('href');
                        var isLogin = urlEncours.indexOf('/?login=1');
                        if (isLogin > 0 && response.redirect !== undefined) {
                            location.href = response.redirect;
                        } else {
                            window.location.reload();
                        }
                    } else {
                        $('#login-form-alert').text(response.message);
                        $('#login-form-alert').show();
                        //Afficher information pour réinitialiser password
                        $('#notif-change-pwd').hide();
                    }
                    spinner.stop();
                }
            });
        }

        return false;
    });

    $(document).on('submit', '#registration-form', function (event) {
        event.preventDefault();
        //$('#registration-form').submit(function() {
        var isValid = 1;
        var dateOfbearth = $('#sonata_user_registration_form_dateOfBirth').val();
        var yearDob = parseInt(dateOfbearth.split('/')[2]);
        var currentDate = new Date();
        var age = currentDate.getFullYear() - yearDob;
        var hasHachette_1 = $("#sonata_user_registration_form_hasGuideHachette_1").is(':checked');
        var hasHachette_0 = $("#sonata_user_registration_form_hasGuideHachette_0").is(':checked');
        var isInvalidZipCode = $('.zipCodeNotValid').val();
        var patternZipCode = /^[0-9]{1}/;
        var patternEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
        var isConnected = $("#is-connected").data("connected");

        //reset all message error
        $('#registration-form .form-row.required').removeClass('error');
        $('.error_condition_pop').hide();
        $('.age-min').css('color', 'black');

        $('#registration-form .form-row.required').each(function () {


            var $this = $(this);
            var error = $(this).find('.messageError').val();
            var input = $(this).find('input:visible');

            if (input.val() === '' || ( input.is(':radio, :checkbox') && !input.is(':checked'))) {
                isValid = 0;
                $this.removeClass('error').addClass('error');
                $this.append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');
                hideHint();
                isValid = 0;
                return false;
            } else {
                switch (input.attr('id')) {
                    case 'sonata_user_registration_form_username':
                        if (input.val().length < 5) {
                            error = "Le pseudo est trop court";
                            isValid = 0;
                        }
                        break;
                    /*case 'sonata_user_registration_form_lastname':
                        if (input.val().length < 5) {
                            error = "Le prénom est trop court";
                            isValid = 0;
                        }
                        break;
                    case 'sonata_user_registration_form_firstname':
                        if (input.val().length < 5) {
                            error = "Le nom est trop court";
                            isValid = 0;
                        }
                        break;*/
                    case 'sonata_user_registration_form_dateOfBirth':
                        if (age < 18) {
                            var divDateOfBirth = $("#sonata_user_registration_form_dateOfBirth").parent().parent();
                            error = "Vous devez être majeur pour vous inscrire !";
                            $('.age-min').css('color', 'red');
                            isValid = 0;
                        }
                        break;
                    case 'sonata_user_registration_form_email':
                        if (!patternEmail.test(input.val())) {
                            error = $('.adressNotValid').val();
                            isValid = 0;
                        }
                        break;
                    /*case 'sonata_user_registration_form_postalCode':
                        if (!patternZipCode.test(input.val())) {
                            error = "Le code postal est incorrect";
                            isValid = 0;
                        }
                        break;*/
                }
                if (!isValid) {
                    if (divDateOfBirth !== 'undefined') {
                        $this.removeClass('error').addClass('error');
                        $this.append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');
                    } else {
                        $this.removeClass('error').addClass('error');
                        $this.append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');
                    }
                    hideHint();
                    return false;
                }
            }

        });

        if (isValid === 1) {
            var spinner = setConnectionRegistrationLoading();
            spinner.spin($('.overlay-connection-registration')[0]);
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function (response) {
                    switch (response.code) {
                        case 200:
                            $('.overlay-connection-registration').removeClass('active');
                            $('.overlay-inscription-confirmation').addClass('active');
                            /*setTimeout(function(){
                             $('.overlay-inscription-confirmation').removeClass('active');
                             }, 45000);*/
                            $(document).on('click', "#close-confirmation", function () {
                                window.location.reload();
                            });

                            setDataLayerCreateCompte(1, 0, isConnected);

                            if ($('#registration-form #sonata_user_registration_form_newsletter').is(':checked')) {
                                setDataLayerPushForNewsletter(3);
                            }

                            break;
                        case 400:
                            $('.overlay-connection-registration .box-registration').html(response.content);
                            break;
                        default:
                            break;
                    }
                    spinner.stop();
                }
            });
        }

        return false;
    });
   
    $(document).on('submit', '#newsletter-form', function (event) {
        event.preventDefault();
        var isValid = 0;

        $('#newsletter-form .form-row.required').each(function () {    
            isValid = 1;
            var $this = $(this);
            var error = $this.find('.messageError').val();
            var input = $this.find('input:visible');
            var patternZipCode = /^[0-9]{1}/;
            // var pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
            var pattern = /([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            var email = $("#prospect_e_mail").val();
            var emailNotValid = $('#newsletter-form .form-row.required').find('.messageError.adressNotValid').val();
            
            var dateOfbearth = $('#prospect_dateNaissance').val();
            var yearDob = parseInt(dateOfbearth.split('/')[2]);
            var currentDate = new Date();
            var age = currentDate.getFullYear() - yearDob;
            $this.removeClass('error');
            if (( input.is(':radio, :checkbox') && !input.is(':checked'))) {
                isValid = 0;
                $(this).removeClass('error').addClass('error');
                $(this).append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');

                return false;
            } else {
                switch (input.attr('id')) {
                    case 'prospect_e_mail':
                        if (!pattern.test(email)) {
                            $('#newsletter-form .form-row.required').removeClass('error').addClass('error');
                            $('.adresseMessageError').append('<div class="hint"><i class="icon icon-error-red"></i> ' + emailNotValid + '</div>');
                            isValid = 0;
                        }
                        break;
                    case 'prospect_nom':
                        if (input.val().length < 5) {
                            error = "Le nom est trop court";
                            isValid = 0;
                        }
                        break;
                    case 'prospect_prenom':
                        if (input.val().length < 5) {
                            error = "Le prénom est trop court";
                            isValid = 0;
                        }
                        break;
                    case 'prospect_dateNaissance':
                        if (input.val().length > 0) {
                            if (isNaN(age) || age < 18) {
                                error = "Vous devez être majeur pour vous inscrire !";
                                isValid = 0;
                            }
                        }
                        break;
                    /*case 'prospect_codePostal':
                        if (!patternZipCode.test(input.val())) {
                            error = "Le code postal est incorrect";
                            isValid = 0;
                        }
                        break;*/
                    case 'prospect_pays':
                        if (input.val() === '') {
                            error = "Veuillez saisir votre pays";
                            isValid = 0;
                        }
                        break;
                }
                if (!isValid) {
                    $this.removeClass('error').addClass('error');
                    $this.append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');
                    hideHint();
                    return false;
                }
            }
        });

        if ($('#newsletter-form .form-row.required .hint').length > 0) {
            setTimeout(function () {
                $('.form-row .hint').remove();
            }, 2000);
            return false;
        }

        if (isValid === 1) {
            var spinner = setConnectionRegistrationLoading();
            spinner.spin($('.overlay-newsletter')[0]);
            $('#newsletter-form .form-row.required').removeClass('error');
            $('.hint').remove();

            $.ajax({
                type: "POST",
                url: $('#newsletter-form').attr('action'),
                data: $('#newsletter-form').serialize(),

                success: function (response) {
                    spinner.stop();
                    if (response.code === 200) {
                        setDataLayerPushForNewsletter($('#newsletter-form input[name=gmtNewsletterZone]').val());
                        $('.overlay-newsletter .block-newsletter').html(response.content);
                        $(document).on('click', "#close-newsletter", function () {
                            window.location.reload();
                        });
                    } else if (response.used === 1) {
                        var error = $('.adressDuplicated').val();
                        $('#newsletter-form .form-row.required').eq(0).addClass('error').append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');
                        setTimeout(function () {
                            $('.form-row .hint').remove();
                        }, 2000);
                    }
                }
            });
        }

        return false;
    });

    /* abonnement : validation etape 2 */
    $(document).on('submit', '#step_2_connexion', function () {

        var isValid = 0;
        var isConnected = $("#is-connected").data("connected");
        var formule = $("#step_2_connexion").data('formule');

        $('#step_2_connexion .form-row.required').each(function () {
            var $this = $(this);
            var error = $this.find('.messageError').val();
            var input = $this.find('input:first-child');
            var pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
            var email = $("#step_2_connexion_email").val();
            var emailNotValid = $('#step_2_connexion .form-row.required').find('.messageError').val();

            if (input.val() === '') {
                isValid = 0;
                $(this).removeClass('error').addClass('error');
                $('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>').insertBefore($(input));

                return false;
            } else if(input.attr('id') === 'step_2_connexion_email') {
                if (pattern.test(email)) {
                    isValid = 1;
                } else {
                    $('#step_2_connexion .form-row.required').removeClass('error').addClass('error');
                    $('<div class="hint"><i class="icon icon-error-red"></i> ' + emailNotValid + '</div>').insertBefore($("#step_2_connexion_email"));
                }
            }
        });

        if ($('#step_2_connexion .form-row.required .hint').length > 0) {
            setTimeout(function () {
                $('.form-row .hint').remove();
            }, 2000);
            return false;
        }

        if (isValid === 1) {
            setDataLayerPushCheckout(2, formule, isConnected);
            setTimeout(function () {
                return true
            }, 300);
            return true;
        } else {
            return false;
        }
    });

    $(document).on('submit', '#resetting-form', function () {
        var spinner = setConnectionRegistrationLoading();
        spinner.spin($('.overlay-mdpoublie')[0]);
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: $(this).serialize(),
            async: false,
            success: function (response) {
                var $this = $('#resetting-form').find(".form-row.required");
                if (response.code === 401) {
                    var error = response.message;
                    $this.removeClass('error').addClass('error');
                    $this.append('<div class="hint"><i class="icon icon-error-red"></i> ' + error + '</div>');
                } else if (response.code === 402) {
                    var error = response.message;
                    $this.removeClass('error').addClass('error');
                    $this.append('<div class="hint" style="height:50px;"><i class="icon icon-error-red"></i> ' + error + '</div>');
                } else if (response.code === 200) {
                    $('#message-confirmation').html(response.message);
                    $('#message-confirmation').removeClass('hide');
                    $this.removeClass('error');
                    $('.form-row .hint').remove();
                    setTimeout(function () {
                        $('.overlay-mdpoublie').removeClass('active');
                    }, 20000);
                }
            }
        });
        spinner.stop();

        return false;
    });

    /*$("#newsletter-form").validate({
     errorElement: 'input',
     onkeyup: false,
     focusCleanup: true,
     focusInvalid: false,
     rules: {
     "prospect[email]": {
     required: true,
     email: true
     },
     "prospect[certifie]": {
     required: true,
     email: true
     }
     },
     submitHandler: function (form) {
     form.submit();
     }
     });*/

    $(document).on('submit', 'form.comment-form', function () {
        var spinner = setCommentLoading();
        spinner.spin($(this).find('.comment-loading')[0]);
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: $(this).serialize(),
            async: false,
            success: function (response) {
                if (response.code === 500) {
                    $('.overlay-connection-registration').addClass('active');
                } else if (response.code === 200 && response.content !== '') {
                    /*$('#bloc-comments-list').html(response.content);
                     $('#bloc-comments-list .comment:first').css('border', '1px solid #0f0');
                     $('strong.total-comments').html(response.totalResult);
                     $('#comments_texte').val('');
                     $('#bloc-comments-list div.display-none').hide();
                     setTimeout(function(){
                     $('#bloc-comments-list .comment:first').css('border', 'none');
                     }, 3000);*/
                    window.location.reload();
                }
            }
        });
        spinner.stop();

        return false;
    });

    $(document).on('submit', 'form.form-newsletter, form#formNewsletterFooter', function () {
        var email = $(this).find('input[name=email]').val();
        $(this).find('input[name=email]').val('');
        $('.block-newsletter #newsletter-form input#gmtNewsletterZone').val(2);

        if (email !== '') {
            $('.block-newsletter #newsletter-form input#prospect_e_mail').val(email);
        }
        $('.overlay-newsletter').addClass('active');

        return false;
    });

    $(document).on('click', 'a.connectez-vous', function () {
        $('.overlay-connection-registration').addClass('active');

        return false;
    });
    $(document).on('click', 'a.forgot-password', function () {
        $('.overlay-mdpoublie').addClass('active');

        return false;
    });
    $(document).on('click', 'form.comment-form .vote .stars .icon-circle', function () {
        if ($(this).parent('div.stars').hasClass('connectez-vous')) {
            $('.overlay-connection-registration').addClass('active');
        } else {
            $('form.comment-form .vote .stars .icon-circle').removeClass('icon-black');
            $(this).parent('div.stars').parent('.vote').find('input.comments_note').val($(this).attr('data-note'));
            $('form.comment-form .vote .stars .icon-circle:lt('+$(this).attr('data-note')+')').addClass('icon-black');
        }

        return false;
    });
    $(document).on('focus', 'form.comment-form textarea.comments_texte', function () {
        if ($(this).parent('div.comment').hasClass('connectez-vous')) {
            $('.overlay-connection-registration').addClass('active');
        }

        return false;
    });
    $(document).on('click', 'a.btn-afficher-tous-comments', function () {
        $('#bloc-comments-list div.display-none').show('slow');
        $(this).remove();

        return false;
    });
    $(document).on('click', '.menu .login', function () {
        $('.overlay-connection-registration').addClass('active');

        return false;
    });
    $(document).on('click', '.menu .newsletter, a.newsletter-link', function () {
        $('.overlay-newsletter').addClass('active');
        $('.block-newsletter #newsletter-form input#gmtNewsletterZone').val(1);
        return false;
    });
    $(document).on('click', '.popup .close', function () {
        if ($(this).attr('id') === 'close-newsletter') {
            $('#prospect_e_mail').val('');
            $('#prospect_certifie_0').attr('checked', false);
            $('#prospect_optin').attr('checked', false);
        }
        $(this).parents('.popup').removeClass('active');
    });
    // Main function
    $.fn.scrollUp = function (options) {

        // Ensure that only one scrollUp exists
        if (!$.data(document.body, 'scrollUp')) {
            $.data(document.body, 'scrollUp', true);
            $.fn.scrollUp.init(options);
        }
    };

    // Init
    $.fn.scrollUp.init = function (options) {

        // Define vars
        var o = $.fn.scrollUp.settings = $.extend({}, $.fn.scrollUp.defaults, options),
            triggerVisible = false,
            animIn, animOut, animSpeed, scrollDis, scrollEvent, scrollTarget, $self;

        // Create element
        if (o.scrollTrigger) {
            $self = $(o.scrollTrigger);
        } else {
            $self = $('<a/>', {
                id: o.scrollName,
                href: '#top'
            });
        }

        // Set scrollTitle if there is one
        if (o.scrollTitle) {
            $self.attr('title', o.scrollTitle);
        }

        $self.appendTo('body');

        // If not using an image display text
        if (!(o.scrollImg || o.scrollTrigger)) {
            $self.html(o.scrollText);
        }

        // Minimum CSS to make the magic happen
        $self.css({
            display: 'none',
            position: 'fixed',
            zIndex: o.zIndex
        });

        // Active point overlay
        if (o.activeOverlay) {
            $('<div/>', {
                id: o.scrollName + '-active'
            }).css({
                position: 'absolute',
                'top': o.scrollDistance + 'px',
                width: '100%',
                borderTop: '1px dotted' + o.activeOverlay,
                zIndex: o.zIndex
            }).appendTo('body');
        }

        // Switch animation type
        switch (o.animation) {
            case 'fade':
                animIn = 'fadeIn';
                animOut = 'fadeOut';
                animSpeed = o.animationSpeed;
                break;

            case 'slide':
                animIn = 'slideDown';
                animOut = 'slideUp';
                animSpeed = o.animationSpeed;
                break;

            default:
                animIn = 'show';
                animOut = 'hide';
                animSpeed = 0;
        }

        // If from top or bottom
        if (o.scrollFrom === 'top') {
            scrollDis = o.scrollDistance;
        } else {
            scrollDis = $(document).height() - $(window).height() - o.scrollDistance;
        }

        // Scroll function
        scrollEvent = $(window).scroll(function () {
            if ($(window).scrollTop() > scrollDis) {
                if (!triggerVisible) {
                    $self[animIn](animSpeed);
                    triggerVisible = true;
                }
            } else {
                if (triggerVisible) {
                    $self[animOut](animSpeed);
                    triggerVisible = false;
                }
            }
        });

        if (o.scrollTarget) {
            if (typeof o.scrollTarget === 'number') {
                scrollTarget = o.scrollTarget;
            } else if (typeof o.scrollTarget === 'string') {
                scrollTarget = Math.floor($(o.scrollTarget).offset().top);
            }
        } else {
            scrollTarget = 0;
        }

        // To the top
        $self.on('click',function (e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: scrollTarget
            }, o.scrollSpeed, o.easingType);
        });
    };

    // Defaults
    $.fn.scrollUp.defaults = {
        scrollName: 'scrollUp',      // Element ID
        scrollDistance: 300,         // Distance from top/bottom before showing element (px)
        scrollFrom: 'top',           // 'top' or 'bottom'
        scrollSpeed: 300,            // Speed back to top (ms)
        easingType: 'linear',        // Scroll to top easing (see http://easings.net/)
        animation: 'fade',           // Fade, slide, none
        animationSpeed: 200,         // Animation in speed (ms)
        scrollTrigger: false,        // Set a custom triggering element. Can be an HTML string or jQuery object
        scrollTarget: false,         // Set a custom target element for scrolling to. Can be element or number
        scrollText: 'Scroll to top', // Text for element, can contain HTML
        scrollTitle: false,          // Set a custom <a> title if required. Defaults to scrollText
        scrollImg: false,            // Set true to use image
        activeOverlay: false,        // Set CSS color to display scrollUp active point, e.g '#00FFFF'
        zIndex: 2147483647           // Z-Index for the overlay
    };

    // Destroy scrollUp plugin and clean all modifications to the DOM
    $.fn.scrollUp.destroy = function (scrollEvent) {
        $.removeData(document.body, 'scrollUp');
        $('#' + $.fn.scrollUp.settings.scrollName).remove();
        $('#' + $.fn.scrollUp.settings.scrollName + '-active').remove();

        // If 1.7 or above use the new .off()
        if ($.fn.jquery.split('.')[1] >= 7) {
            $(window).off('scroll', scrollEvent);

            // Else use the old .unbind()
        } else {
            $(window).unbind('scroll', scrollEvent);
        }
    };

    //soumission du formulaire de demande de contact
    $(document).on('submit', '#contact-form', function () {
        var propos = $('#contact_type').val();
        var objet = $('#contact_subject').val();
        var caractere = $('textarea#contact_body').val().length;

        setDataLayerContact(propos, objet, caractere);

    });

    $.scrollUp = $.fn.scrollUp;

})(jQuery, window, document);

// scrollup
$(function () {
    $.scrollUp();
});

// form header search
$(function () {
    $('.region-toggleable li').on('click',function () {
        var selected = $(this).text();
        $('#form_recherche_vins').append('<input type="hidden" name="region" id="region" value="' + selected + '">');
    });
    $('.color-toggleable li').on('click',function () {
        var selected = $(this).text().toLowerCase();
        $('#form_recherche_vins').append('<input type="hidden" name="couleur" id="couleur" value="' + selected + '">');
    });
    $('.price-toggleable li').on('click',function () {
        var selected = $(this).text();
        $('#form_recherche_vins').append('<input type="hidden" name="prix" id="prix" value="' + selected + '">');
    });
    $('.rating-toggleable li a').on('click',function () {
        var selected = $(this).attr('name');
        $('#form_recherche_vins').append('<input type="hidden" name="note" id="note" value="' + selected + '">');
    });
    $('.favorite-toggleable li').on('click',function () {
        $('#form_recherche_vins').append('<input type="hidden" name="coeur" id="coeur" value="T">');
    });
    $('.achat-toggleable li a').on('click',function () {
        var selected = $(this).attr('name');
        $('#form_recherche_vins').append('<input type="hidden" name="marchand" id="marchand" value="' + selected + '">');
    });
});

// form facets search
$(function () {
    // submit new search
    $('#formSearch').submit(function (event) {
        var newSearch = $('#newSearch').val();
        var url = $('#newSearch').attr('name') + '?search=' + newSearch;

        event.preventDefault();

        if (newSearch && url) {
            $(location).attr('href', url);
        }

    });

    // search engine facet
    $("input.searchVins, .filter-content span.input input[type=checkbox]").on('click',function () {
        var param = $(this).attr('name').split('_')[0];
        var checked = $(this).attr('checked');
        var data_url = $(this).data('url');
        var queryString = "";

        if (!$(this).attr('name').match(/_/)) {
            var value = decodeURIComponent($(this).attr('name'));
        } else {
            var value = decodeURIComponent($(this).attr('name').split('_')[1]);
        }

        if (document.URL.match(/\/all\/+\?{0,1}/g) && value !== 'vin' && value !== 'producteur') {
            var url = document.URL.replace(/all+\?{0,1}/g, value);
        } else {
            var url = $(this).attr('value');
        }
        if (checked && $(this).attr('id') !== 'all_marchands') {
            var protocol = window.location.protocol;
            var host = window.location.hostname;
            var pathname = window.location.pathname;
            var controller = protocol + '//' + host + pathname + 'search/delete/' + param + '_' + value + '?data_url=' + data_url;

        

            $.ajax({
                type: "GET",
                url: controller,
                data: {
                    'route': $('#routeName').val()
                },
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
                success: function (text) {
                    if (text) {
                        $(location).attr('href', text);
                    }
                }
            });
        } else {
            $(location).attr('href', url);
        }
    });

});

$(function () {
    //search articles
    $("input.searchArticles").on('click',function () {
        var categories = [];
        $("input.searchArticles:checked").each(function (i, el) {
            categories.push($(el).val());
        });
        var routeName = 'ghv_articles_home_paginated';
        var routeParam = {
            page: 1
        };
        if (categories.length > 0) {
            routeParam['categories'] = categories.join('|');
        }

        document.location.href = Routing.generate(routeName, routeParam);
    });
});

//Cookie de newsletter
$(function () {
    if ($('#overlay-newsletter').length > 0) {
        var firstNavigationListener = readCookie('listen.navigationOne');
        var secondNavigationListener = readCookie('listen.navigationTwo');
        if (firstNavigationListener === "" || firstNavigationListener === 'undefined' || firstNavigationListener === null) {
            createCookie('listen.navigationOne', 1, 1);
        } else if (secondNavigationListener === "" || secondNavigationListener === 'undefined' || secondNavigationListener === null) {
            createCookie('listen.navigationTwo', 1, 1);
        }
        var isNotMember = 0;
        $('.overlay-newsletter form.notmember').each(function () {
            isNotMember = 1;
        });
        //ouverture du POP IN INSCRITION NEWSLETTER a la deuxieme navigation HVINS-832
        if (isNotMember && (secondNavigationListener === "" || secondNavigationListener === 'undefined' || secondNavigationListener === null) && (firstNavigationListener !== "" && firstNavigationListener != 'undefined' && firstNavigationListener != null)) {
            if (! (document.location.href).includes('login=1')) {
                openNewsletter();
            }
        }
    }
});

/* Abonnement */
$(function () {
    $(document).on('click', '.newabonne-etape2', function () {
        var content = $("#coordonnees-content").html();
        if (content === '' || content === null) {
            $('#abonnement-page .tab-area nav li[data-target=".tab-1"]').trigger('click');
            alert("Merci de cliquer sur JE M'ABONNE pour voir le contenu de cet onglet");
        }
    });
    $(document).on('click', '.newabonne-etape3', function () {
        var contentTab3 = $("#recap-content").html();
        var contentTab2 = $("#coordonnees-content").html();
        if (contentTab3 === '' && contentTab2 === '') {
            $('#abonnement-page .tab-area nav li[data-target=".tab-1"]').trigger('click');
            alert("Vous devez vous abonner en premier");
        }
        if (contentTab2 !== '' && contentTab3 === '') {
            $('#abonnement-page .tab-area nav li[data-target=".tab-2"]').trigger('click');
            alert("Vous devez confirmer votre abonnement pour passer à cet onglet");
        }
    });
});

// cookie bar
// $.cookieBar({
//     message: 'Bienvenue sur Hachette Vins ! Pour améliorer votre expérience et vous offrir des contenus et publicités en accord avec vos centres d\'intérêt, nous utilisons des cookies.',
//     acceptButton: true,
//     acceptText: 'OK',
//     declineButton: false,
//     declineText: 'Disable Cookies',
//     policyButton: true,
//     policyText: 'En savoir plus',
//     policyURL: $('input#url_cookies').val(),
//     autoEnable: true,
//     acceptOnContinue: false,
//     expireDays: 365,
//     forceShow: false,
//     effect: 'slide',
//     element: 'body',
//     append: false,
//     fixed: true,
//     bottom: true,
//     zindex: '1000',
//     redirect: '/',
//     domain: 'www.hachette-vins.com',
//     referrer: 'www.hachette-vins.com'
// });


// fiche producteur
// Afficher tous les autres vins du producteur
$('#showAllVinsOfProductor').on('click',function () {
    $('div.items .slides li.item').show('fast');
});

