import jQuery from 'jquery';

export var spinnerDefaultOptions = {
    lines: 12,
    length: 10,
    width: 5,
    radius: 12,
    corners: 1,
    rotate: 0,
    direction: 1,
    color: '#000',
    speed: 1,
    trail: 60,
    shadow: false,
    hwaccel: false,
    opacity: 0.5,
    className: 'spinner',
    zIndex: 2e9,
    top: '50%',
    left: '50%'
};

jQuery(function ($) {

    $(document).ready(function() {
        var substringMatcher = function(strs) {
            return function findMatches(q, cb) {
                var matches, substrRegex;
                // an array that will be populated with substring matches
                matches = [];
                // regex used to determine if a string contains the substring `q`
                substrRegex = new RegExp(q, 'i');
                // iterate through the pool of strings and for any string that
                // contains the substring `q`, add it to the `matches` array
                $.each(strs, function(i, str) {
                if (substrRegex.test(str)) {
                    // the typeahead jQuery plugin expects suggestions to a
                    // JavaScript object, refer to typeahead docs for more info
                    matches.push({ value: str });
                }
                });
                cb(matches);
            };
        };

        // var appellations = [];
        // var millesimes = [];
        // {% if appellations is defined and appellations is not empty %}
        //     {% for entity in appellations if entity.term is defined and entity.count is defined %}
        //         appellations.push("{{ entity.term ~ ' (' ~ entity.count|number_format(0, '', ' ') ~ ')' }}");
        //     {% endfor %}
        // {% endif %}
        // {% if millesime is defined and millesime is not empty %}
        //     {% for entity in millesime if entity.term is defined and entity.count is defined %}
        //         millesimes.push("{{ entity.term ~ ' (' ~ entity.count|number_format(0, '', ' ') ~ ')' }}");
        //     {% endfor %}
        // {% endif %}

        var value = null;
        var val = null;
        var zClass = null;

        $('#aucomplete-appellation').typeahead({
                hint: true,
                highlight: true,
                minLength: 3
            },
            {
                name: 'appellations',
                displayKey: 'value',
                source: substringMatcher(appellations)
            }
        ).bind('typeahead:selected', function(obj, selected, name) {
            value = selected.value.toString();
            $('.collapse-ge span.appellation').each(function(){
                val = $(this).html();
                zClass = $(this).attr('id');
                if (val == value) {
                    $("input."+zClass).trigger('click');

                    return false;
                }
            });
        });

        $('#aucomplete-millesimes').typeahead({
                hint: true,
                highlight: true,
                minLength: 3
            },
            {
                name: 'millesimes',
                displayKey: 'value',
                source: substringMatcher(millesimes)
            }
        ).bind('typeahead:selected', function(obj, selected, name) {
            value = selected.value.toString();
            $('.collapse-ge span.millesime').each(function(){
                val = $(this).html();
                zClass = $(this).attr('id');
                if (val == value) {
                    $("input."+zClass).trigger('click');

                    return false;
                }
            });

            return false;
        });

        $(".button-appellation").click(function() {
            value = $('#aucomplete-appellation').val();
            if (value != '') {
                $('.collapse-ge .filter-search  span.appellation').each(function(){
                    val = $(this).html();
                    val2 = val.split(' (');
                    zClass = $(this).attr('id');
                    if (val == value || (val2.length > 1 && val2[0] == value)) {
                        $("input."+zClass).trigger('click');

                        return false;
                    }
                });
            }
        });

        $(".button-millesime").click(function() {
            value = $('#aucomplete-millesimes').val();
            if (value != '') {
                $('.collapse-ge .filter-search span.millesime').each(function(){
                    val = $(this).html();
                    val2 = val.split(' (');
                    zClass = $(this).attr('id');
                    if (val == value || (val2.length > 1 && val2[0] == value)) {
                        $("input."+zClass).trigger('click');

                        return false;
                    }
                });
            }
        });

    });

});
